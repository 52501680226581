import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="TERIK" keywords={[`terik`, `time tracking`]} />
    <h1>Zadej, sleduj, vyhodnoť</h1>
    <h2>TERIK je unikátní aplikace pro vedení projektů a&nbsp;sledování ekonomiky firmy.</h2>
    <div className="invite">
      <a href="https://app.terik.cz/register" className="btn">Vyzkoušet zdarma</a>
    </div>
  </Layout>
)

export default IndexPage
