import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const Header = ({ siteTitle }) => (
  <div className="anonymous-header">
    <div className="branding">
      <a href="/">{siteTitle}</a>
    </div>
    <div className="links">
      <a href="https://app.terik.cz/login">Přihlásit</a>
      <a href="https://app.terik.cz/register">Registrovat</a>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
